<template>
  <div>
    <common-dialog
      :is-visible.sync="dialogVisible"
      :width="'800px'"
      title="上传图片"
      class="dialog-content"
      :modal="false"
      @dialogClosed="closed"
    >
      <div class="upload-image">
        <el-upload
          :action="`${$uploadApi}${uploadSOOFileUrl}`"
          :http-request="UploadOssFileOCRHandler"
          :show-file-list="false"
        >
          <div class="upload-file">
            <img v-if="imgUrl" :src="imgUrl" alt="">
            <div v-else class="icon">
              <i class="el-icon-picture-outline" style="font-size: 80px" />
            </div>
          </div>
        </el-upload>
      </div>
      <div class="but">
        <el-button size="small" type="primary" @click="submit">确认</el-button>
        <el-button size="small" type="danger" @click="closed">取消</el-button>
      </div>
    </common-dialog>
  </div>
</template>

<script>
import OSS from 'ali-oss'
export default {
  props: {
    folderName: {
      type: String,
      default: 'enterpriseinfo'
    },
    viewImage: {
      type: String,
      default: ''
    },
    isBase64: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      msg: '',
      imgUrl: '',
      uploadSOOFileUrl: 'Upload/UploadImageOCR',
      dialogVisible: false,
      dialogImageUrl: '',
      Visible: false
    }
  },
  watch: {
    viewImage: {
      handler(val) {
        if (val && val.length > 0) {
          // this.imgUrl = val
          if (this.isBase64) {
            this.imgUrl = `${val}`
            return
          }

          if (val.indexOf('https') > -1) {
            this.imgUrl = `${val}`
          } else {
            this.imgUrl = `${this.$uploadApi}${val}`
          }
        } else {
          this.imgUrl = ''
        }
      },
      immediate: true
    }
  },
  created() {
    this.$http.post('api/Oss/Getkey', {}).then((res) => {
      console.log(res)
    })
  },
  methods: {
    open() {
      this.dialogVisible = true
    },
    UploadOssFileOCRHandler(res) {
      if (this.isBase64) {
        console.log(res)
        this.getBase64Image(res.file).then((res) => {
          console.log(res)
          this.$emit('successUpload', res) // 获取到base64格式图片
        })
      } else {
        this.uploadSOOFile(res.file)
      }
    },

    getBase64Image(file) {
      return new Promise((resolve) => {
        var reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = function() {
          resolve(reader.result)
        }
      })
    },
    downLoadFile() {
      window.open(this.imgUrl, '_blank')
    },
    clearImgUrl() {
      this.imgUrl = ''
      this.$emit('delImg')
    },
    generPath(imgType) {
      const dateStr = `${new Date().getFullYear()}/${
        new Date().getMonth() + 1
      }/${new Date().getDate()}`
      return (
        (process.env.NODE_ENV === 'production' ? 'prod' : 'test') +
        `/manage/guanwang/${dateStr}/1_${new Date().getTime()}.${imgType}`
      )
    },
    // 上传 oss云服务器
    uploadSOOFile(file) {
      this.$http.post('api/Oss/Getkey', {}).then((res) => {
        if (res.code === 1) {
          const obj = Object.assign({}, res.data, { secure: true })
          var imgtype = file.type.substr(6, 4)
          // const store = process.env.NODE_ENV === 'production' ? `${this.folderName}/unload/${new Date().getTime()}_${randomNum()}.${imgtype}` : `${this.folderName}test/unload/${new Date().getTime()}_${randomNum()}.${imgtype}`
          const store = this.generPath(imgtype)
          new OSS(obj).put(store, file).then((res) => {
            const url = 'https://oss.shttwl.com/' + store
            this.$emit('successUpload', url)
            return
          })
        }
      })
    },
    closed() {
      this.dialogVisible = false
      this.imgUrl = ''
    },
    submit() {
      console
      this.$emit('onImageHtml')
      this.dialogVisible = false
      this.imgUrl = ''
    }
  }
}
</script>

<style lang="scss" scoped>

.upload-image {
  width: 100%;
  ::v-deep .el-upload {
    width: 100%;
    padding: 0 10px;
    box-sizing: border-box;
  }
  .upload-file {
    width: 100%;
    height: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 2px dashed #dedede;
    border-radius: 8px;
    background-color: #fbfdff;
    position: relative;
    box-sizing: border-box;
    cursor: pointer;
    img {
      width: 100%;
      height: 100%;
    }
  }

  .upload-image {
    position: absolute;
    bottom: 0;
    right: 10px;
    background: rgba($color: #000000, $alpha: 0.5);
    padding: 0 5px;
    display: flex;
    flex-direction: column;
  }
}
.but {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 80px;
  & .el-button:nth-child(1) {
    margin-right: 15px;
  }
}
</style>
